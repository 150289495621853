<template>
  <div class="certificate">
    <Title :title="certificate"></Title>
    <div class="cer_container flex-row wei-container">
      <div class="cer-item flex1" v-for="(item, index) in list" :key="index">
        <img :src="imgUploadUrl + item.img" alt="" />
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import Title from "@/components/title";
import { mapGetters, mapMutations } from "vuex";
import { queryIndexCertificate } from "@/api/";

export default {
  name: "certificate",
  components: {
    Title,
  },
  data() {
    return {
      list: [],
      certificate: {},
    };
  },
  computed: {
    ...mapGetters(["imgUploadUrl"]),
  },
  created() {
    this.certificate = this.$t("certificate");
    this.getQueryData();
  },
  mounted() {},
  methods: {
    getQueryData() {
      queryIndexCertificate().then((res) => {
        this.list = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.certificate {
  padding-bottom: 150px;

  .cer-item {
    // width: 295px;
    // height: 390px;
    // margin-right: 40px;
    // display: inline-block;
    justify-content: space-around;
    &:nth-child(4) {
      margin-right: 0;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .name {
      font-size: 16px;
      margin-top: 19px;
      text-align: center;
    }
  }
}
</style>
