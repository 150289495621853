<template lang="">
  <div class="author">
    <Banner :title="certificate"></Banner>
    <Certificate></Certificate>
  </div>
</template>
<script>
import Certificate from "@/components/Certificate";
import Banner from "@/components/Banner";
export default {
  components: {
    Certificate,
    Banner
  },
    data() {
    return {
      certificate: {},
    };
  },
  created() {
    this.certificate = this.$t("certificate");
  },
};
</script>
<style lang="scss">
.author {
  .certificate {
    padding-bottom: 200px;
    .c-title {
      .cname {
        margin-top: 100px;
      }
    }
  }
}
</style>
